import renderCarousel from './services/carouselRenderer';
import CarouselWidget, { CarouselProps } from './lib/carouselProps';
import isEditModeDisabled from './lib/isEditModeDisabled';
// eslint-disable-next-line import/named
import { carouselId, carouselContainerId, CURRENT_SLIDE } from './constants';
import getIndexFromObjectPath from './services/parseObjectPath';
import { setupCarouselArrows } from './components/arrows/arrows';
import { setupCarouselBullets } from './components/bullets/bullets';
import { getContentEditableParentElement } from './services/inline/inline';
import { markActiveBullet } from './services/handleSlideAction';

import Siema = require('siema');

export default class Carousel implements CarouselWidget {
    public carousel: Siema;

    private readonly containerSelector: string;

    private readonly carouselSelector: string;

    public readonly uuid: string;

    private props: CarouselProps;

    public constructor(props: CarouselProps) {
        this.props = props;
        this.uuid = this.props._.id;
        this.containerSelector = carouselContainerId(this.uuid);
        this.carouselSelector = carouselId(this.uuid);
    }

    public setup = (): void => {
        this.render();
        if (this.getContainer()) {
            this.initializeCarousel();
        }
    };

    public getProps = (): CarouselProps => this.props;

    public setProps = (props: CarouselProps): void => {
        this.props = props;
    };

    public getEditMode = (): boolean => !isEditModeDisabled(this.getProps());

    public render = (): void => {
        renderCarousel(this.props);
    };

    public initializeCarousel = (): void => {
        this.carousel = new Siema({
            autoPlay: false,
            draggable: false,
            draggableOnMobile: true,
            duration: 400,
            easing: 'ease',
            loop: true,
            preserveInitialState: true,
            selector: this.carouselSelector,
            startIndex: this.getStartIndex(),
            bubbleEventOn: getContentEditableParentElement,
            onChange: (): void => {
                markActiveBullet(this.getContainer(), this.carousel);
            },
        });
        setupCarouselArrows(this.getContainer(), this.carousel, this.getEditMode, this.props.slides.length);
        setupCarouselBullets(this.getContainer(), this.carousel, this.getEditMode, this.props.slides.length);
    };

    public getStartIndex = (): number =>
        getIndexFromObjectPath(
            `${CURRENT_SLIDE}`,
            this.props._.pageBuilderData &&
                this.props._.pageBuilderData.previewState &&
                this.props._.pageBuilderData.previewState.activeChild &&
                this.props._.pageBuilderData.previewState.activeChild.attribute
        );

    public getContainer = (): HTMLElement => document.querySelector(`${this.containerSelector}`);

    public goTo = (slide: number): void => {
        this.carousel.goTo(slide);
        this.setCurrentIndex();
        markActiveBullet(this.getContainer(), this.carousel);
    };

    public setCurrentIndex = (): void => {
        const currentSlide = {
            attribute: `${CURRENT_SLIDE}.${this.carousel.currentSlide}`,
        };
        const { activeChild } = this.props._.pageBuilderData.previewState;
        this.props._.pageBuilderData.previewState.activeChild = { ...activeChild, ...currentSlide };
    };
}
