import { html, TemplateResult } from 'lit-html';

import { CarouselProps, CarouselSlideElement } from '../../lib/carouselProps';
import handleLinkRedirect from '../../services/handleLinkRedirect';
import carouselSlideContent from '../carouselSlideContent/carouselSlideContent';

import { carouselSlideElementBlockStyle, carouselSlideStyle } from './styles';

const carouselSlide = (props: CarouselProps, index: number): TemplateResult => {
    const carouselSlideProps: CarouselSlideElement = props.slides[index];

    return html`
        <div
            class="${carouselSlideStyle}"
            @click="${(event: MouseEvent): void => handleLinkRedirect(event, props, index)}"
            data-test-id="carousel-slide-${index}"
        >
            <div class="${carouselSlideElementBlockStyle(carouselSlideProps)}">
                ${carouselSlideContent(props, index)}
            </div>
        </div>
    `;
};

export default carouselSlide;
