import { Alignment, Visibility, ButtonStyle } from '@bigcommerce/page-builder-sdk';

import { BoxModel } from './widgetSchema/boxModel/boxModel';

import Siema = require('siema');

export default interface CarouselWidget {
    carousel: Siema;
    getProps(): CarouselProps;
    setProps(props: CarouselProps): void;
    getContainer(): HTMLElement;
    render(): void;
    setup(): void;
    getEditMode(): boolean;
    uuid: string;
    initializeCarousel(): void;
    getStartIndex(): number;
    goTo(slide: number): void;
    setCurrentIndex(): void;
}

export interface ActiveChild {
    attribute: string;
}

export interface PreviewState {
    activeChild?: ActiveChild;
    editMode?: boolean;
}

export interface PageBuilderData {
    previewState?: PreviewState;
}

interface ProtectedNamespace {
    id: string;
    pageBuilderData: PageBuilderData;
}

interface FontSize {
    value: number;
    type: string;
}

interface TextElementType {
    visibility: Visibility;
    textStyle: TextStyle;
    fontFamily: string;
    fontWeight: string;
    fontSize: FontSize;
}

interface ButtonElementType {
    visibility: Visibility;
    shape: ButtonStyle;
    buttonColor: string;
    buttonTextColor: string;
}

interface TextBoxElementType {
    visibility: Visibility;
    backgroundColor: string;
}

interface BackgroundElementType {
    imageUrl: {
        src: string;
        type: string;
    };
    color: string;
    type: string;
    imageFit: string;
    imageParallax: string;
    imageAlignment: Alignment;
    imageOpacity: number;
    videoUrl: string;
}

export enum TextStyle {
    BODY_TEXT = 'global_typography_body_text',
    CUSTOM = 'custom',
    DEFAULT = 'default',
    HEADING_1 = 'global_typography_h1',
    HEADING_2 = 'global_typography_h2',
    HEADING_3 = 'global_typography_h3',
    HEADING_4 = 'global_typography_h4',
    HEADING_5 = 'global_typography_h5',
    HEADING_6 = 'global_typography_h6',
    SECONDARY_TEXT = 'global_typography_secondary_text',
}

export enum BackgroundType {
    IMAGE = 'image',
    COLOR = 'color',
    VIDEO = 'video',
}

export interface CarouselSlideElement {
    displayTitle: string;
    displaySubtitle: string;
    displayButtonText: string;
    title: TextElementType;
    description: TextElementType;
    button: ButtonElementType;
    slideLink: string;
    textBox: TextBoxElementType;
    textColor: string;
    buttonLink: string;
    ctaTextSize: string;
    background: BackgroundElementType;
    buttonBorder: string;
    buttonTextSize: string;
    containerHeight: string;
    buttonBorderColor: string;
    contentAlignment: Alignment;
    buttonBorderRadius: string;
    buttonTextHoverColor: string;
    buttonBackgroundColor: string;
    buttonMargin: BoxModel;
    buttonPadding: BoxModel;
    containerMargin: BoxModel;
    containerPadding: BoxModel;
    buttonBackgroundHoverColor: string;
    buttonBackgroundClickColor: string;
}

export interface CarouselProps {
    _: ProtectedNamespace;
    loop: boolean;
    height: string;
    duration: string;
    draggable: boolean;
    easingMethod: string;
    indicatorSize: string;
    showIndicators: boolean;
    indicatorColor: string;
    slides: CarouselSlideElement[];
    arrowBackground: string;
    autoPlayDuration: string;
    indicatorBackground: string;
    activeIndicatorColor: string;
}
