import { css } from 'emotion';

import isEditModeDisabled from '../../lib/isEditModeDisabled';
import { CarouselProps } from '../../lib/carouselProps';

const contentEditableStyling = (props: CarouselProps): string => {
    return css`
        p {
            margin: 0;
        }

        ${!isEditModeDisabled(props) &&
            css`
                min-width: 1rem;
                cursor: text;

                &:focus,
                &:hover {
                    outline: 1px dashed #3c64f4;
                }
            `}
    `;
};

export default contentEditableStyling;
