import Siema from 'siema';
import { html, TemplateResult } from 'lit-html';

import { arrowAriaLabel, markActiveBullet } from '../../services/handleSlideAction';

import { LEFT_ARROW, RIGHT_ARROW } from './constants';
import { carouselArrow, carouselArrowLeft, carouselArrowRight, carouselArrows } from './styles';
import { leftArrowIcon, rightArrowIcon } from './icons';

export const setupCarouselArrows = (
    container: HTMLElement,
    carousel: Siema,
    getEditMode: () => boolean,
    slideCount: number
): void => {
    const leftArrow = container.querySelector(`.${LEFT_ARROW}`) as HTMLElement;
    const rightArrow = container.querySelector(`.${RIGHT_ARROW}`) as HTMLElement;
    if (leftArrow && rightArrow) {
        leftArrow.addEventListener('click', (): void => {
            const editMode = getEditMode();
            if (editMode) {
                return;
            }
            carousel.prev(1);
            markActiveBullet(container, carousel);
            arrowAriaLabel(leftArrow, rightArrow, slideCount, carousel.currentSlide + 1);
        });
        rightArrow.addEventListener('click', (): void => {
            const editMode = getEditMode();
            if (editMode) {
                return;
            }
            carousel.next(1);
            markActiveBullet(container, carousel);
            arrowAriaLabel(leftArrow, rightArrow, slideCount, carousel.currentSlide + 1);
        });
    }
};

export const carouselArrowElement = (slideCount: number): TemplateResult => {
    const ariaLabel = (arrow: string) =>
        arrow === 'leftArrow' ? `Go to slide ${slideCount}` : `Go to slide ${slideCount > 1 ? 2 : slideCount}`;

    return html`
        <div class="${carouselArrows}">
            <button
                class="${carouselArrow} ${carouselArrowLeft} ${LEFT_ARROW}"
                aria-label="${ariaLabel('leftArrow')}"
                data-test-id="carousel-left-arrow"
            >
                ${leftArrowIcon}
            </button>
            <button
                class="${carouselArrow} ${carouselArrowRight} ${RIGHT_ARROW}"
                aria-label="${ariaLabel('rightArrow')}"
                data-test-id="carousel-right-arrow"
            >
                ${rightArrowIcon}
            </button>
        </div>
    `;
};
