import { css } from 'emotion';

import { BULLET_ACTIVE_CLASS_NAME } from './constants';

export const carouselBullets = css`
    -webkit-touch-callout: none;
    user-select: none;
    position: absolute;
    padding: 0.5rem;
    z-index: 100;
    left: 50%;
    display: inline-flex;
    list-style: none;
    transform: translateX(-50%);

    .${BULLET_ACTIVE_CLASS_NAME} {
        background-color: #444444;
    }
`;

export const carouselBullet = css`
    width: 12px;
    height: 12px;
    border: 1px solid #444444;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
    line-height: 0;
    margin: 0.25em;

    :focus {
        outline: none;
    }
`;
