import { css } from 'emotion';

export const carouselContainer = css`
    position: relative;
    width: 100%;
    margin-bottom: 30px;

    * {
        box-sizing: border-box;
    }
`;

export const carouselSlides = css`
    overflow: hidden;
    touch-action: pan-Y;
`;
