export const widgetSelector = (uuid: string): string => `#sd-carousel-${uuid}`;

export const CAROUSEL_NAME = 'bc-carousel';
export const carouselSelector = (uuid: string): string => `${CAROUSEL_NAME}-${uuid}`;
export const carouselId = (uuid: string): string => `#${carouselSelector(uuid)}`;

export const CAROUSEL_CONTAINER_NAME = 'bc-carousel-container';
export const carouselContainerSelector = (uuid: string): string => `${CAROUSEL_CONTAINER_NAME}-${uuid}`;
export const carouselContainerId = (uuid: string): string => `#${carouselContainerSelector(uuid)}`;

export const CURRENT_SLIDE = 'slides';
