import { css } from 'emotion';
import { handleVisibility, Visibility, ScreenSize } from '@bigcommerce/page-builder-sdk';

import { CarouselProps, CarouselSlideElement, TextStyle } from '../../lib/carouselProps';
import renderBorderRadius from '../../lib/borderRadius';
import renderContentContainerSize from '../../lib/contentContainerSize';

const getTitleStyles = (titleStyle: TextStyle, props: CarouselSlideElement): string => {
    switch (titleStyle) {
        case TextStyle.DEFAULT:
            return css`
                font-size: 32px;

                @media screen and (min-width: 801px) {
                    font-size: 36px;
                    font-weight: 500;
                }
            `;
        case TextStyle.CUSTOM:
            return css`
                font-family: ${props.title.fontFamily};
                font-size: ${props.title.fontSize.value}${props.title.fontSize.type}; // prettier-ignore
                font-weight: ${props.title.fontWeight};
            `;
        default:
            return '';
    }
};

const getDescriptionStyles = (titleStyle: TextStyle, props: CarouselSlideElement): string => {
    switch (titleStyle) {
        case TextStyle.DEFAULT:
            return css`
                font-size: 18px;

                @media screen and (min-width: 801px) {
                    font-size: 21px;
                }
            `;
        case TextStyle.CUSTOM:
            return css`
                font-family: ${props.description.fontFamily};
                font-size: ${props.description.fontSize.value}${props.description.fontSize.type}; // prettier-ignore
                font-weight: ${props.description.fontWeight};
            `;
        default:
            return '';
    }
};

export const carouselSlideTitleStyle = (props: CarouselSlideElement): string => {
    return css`
        ${handleVisibility(props.title.visibility)};
        ${getTitleStyles(props.title.textStyle, props)};
        color: ${props.textColor};
    `;
};

export const carouselSlideSubtitleStyle = (props: CarouselSlideElement): string => {
    return css`
        ${handleVisibility(props.description.visibility)};
        ${getDescriptionStyles(props.description.textStyle, props)};
        margin-top: 8px;
        min-height: 22px;
        color: ${props.textColor};
    `;
};

export const slideButtonStyles = (props: CarouselProps, index: number): string => {
    const slideProps = props.slides[index];
    const visibility = handleVisibility(slideProps.button.visibility);

    return css`
        background: ${slideProps.button.buttonColor};
        border: none;
        color: ${slideProps.button.buttonTextColor};
        cursor: pointer;
        font-size: 21px;
        ${visibility === '' ? 'display: inline-block' : visibility};
        margin-top: 24px;
        outline: none;
        padding: 8px 24px;
        pointer-events: auto;
        text-decoration: none;
        ${renderBorderRadius(slideProps.button.shape)};
        font-family: inherit;
    `;
};

export const getTextBoxStyle = (props: CarouselSlideElement): string => {
    if (props.textBox.visibility === Visibility.Show) {
        return css`
            padding: 32px;
            background-color: ${props.textBox.backgroundColor};
            box-shadow: 0px 2px 12px rgba(49, 52, 64, 0.2);

            @media screen and (min-width: 801px) {
                width: 536px;
            }

            @media screen and (min-width: 1261px) {
                width: 550px;
            }
        `;
    }

    return ``;
};

export const slideContentLayout = (props: CarouselSlideElement): string => {
    return css`
        ${getTextBoxStyle(props)}

        @media screen and (min-width: 801px) {
            width: ${renderContentContainerSize(ScreenSize.Tablet, props.contentAlignment.horizontal)};
        }
    `;
};
