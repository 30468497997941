import { html, TemplateResult } from 'lit-html';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { v4 as uuid } from 'uuid';

import { CarouselProps, CarouselSlideElement, TextStyle } from '../../lib/carouselProps';
import { contentEditable } from '../../services/inline/inline';
import contentEditableStyling from '../../services/inline/styles';
import globalStyleClass from '../../services/globalStyles';

import { carouselSlideSubtitleStyle, carouselSlideTitleStyle, slideButtonStyles, slideContentLayout } from './styles';

const carouselSlideElementTitle = (props: CarouselProps, index: number, renderId: string): TemplateResult => {
    const carouselSlideProps: CarouselSlideElement = props.slides[index];
    const {
        _: { id },
    } = props;

    return html`
        ${unsafeHTML(`
            <div 
                class="
                    ${carouselSlideTitleStyle(carouselSlideProps)}
                    ${contentEditable(id, 'displayTitle', index)}
                    ${contentEditableStyling(props)}
                    ${globalStyleClass(carouselSlideProps.title.textStyle)}
                "
                data-render-id="${renderId}"
                data-test-id="carousel-title"
            >
                ${carouselSlideProps.displayTitle}
            </div>

        `)}
    `;
};

const carouselSlideElementSubTitle = (props: CarouselProps, index: number, renderId: string): TemplateResult => {
    const carouselSlideProps: CarouselSlideElement = props.slides[index];
    const {
        _: { id },
    } = props;

    return html`
        ${unsafeHTML(`
            <div 
                class="
                    ${carouselSlideSubtitleStyle(carouselSlideProps)}
                    ${contentEditable(id, 'displaySubtitle', index)}
                    ${contentEditableStyling(props)}
                    ${globalStyleClass(carouselSlideProps.description.textStyle)}
                "
                data-render-id="${renderId}"
                data-test-id="carousel-subtitle"
            >
                ${carouselSlideProps.displaySubtitle}
            </div>
        `)}
    `;
};

const carouselSlideElementButton = (props: CarouselProps, index: number, renderId: string): TemplateResult => {
    const carouselSlideProps: CarouselSlideElement = props.slides[index];
    const {
        _: { id },
    } = props;

    return html`
        <div
            class="
                ${globalStyleClass(TextStyle.DEFAULT)} 
                ${slideButtonStyles(props, index)}
            "
            role="button"
            data-test-id="carousel-cta-button"
        >
            ${unsafeHTML(`
                <div
                    class="${contentEditable(id, 'displayButtonText', index)} ${contentEditableStyling(props)}"
                    data-render-id="${renderId}"
                >
                    ${carouselSlideProps.displayButtonText}
                </div>
            `)}
        </div>
    `;
};

const carouselSlideContent = (props: CarouselProps, index: number): TemplateResult => {
    const carouselSlideProps: CarouselSlideElement = props.slides[index];
    const renderId = uuid();

    return html`
        <div class="${slideContentLayout(carouselSlideProps)}">
            ${carouselSlideElementTitle(props, index, renderId)} ${carouselSlideElementSubTitle(props, index, renderId)}
            ${carouselSlideElementButton(props, index, renderId)}
        </div>
    `;
};

export default carouselSlideContent;
