export default function getIndexFromObjectPath(attribute, path?: string): number {
    if (!path) {
        return 0;
    }

    const attributes = path.split('.');
    const attributeIndex = attributes.indexOf(attribute);

    if (attributeIndex === -1) {
        return 0;
    }

    const index = Number(attributes[attributeIndex + 1]);

    if (!Number.isNaN(index)) {
        return index;
    }

    return 0;
}
