import { render } from 'lit-html';

import { CarouselProps } from '../lib/carouselProps';
import { widgetSelector } from '../constants';
import carousel from '../components/carousel/carousel';

const renderCarousel = (props: CarouselProps): void => {
    const widget = document.querySelector(widgetSelector(props._.id));
    if (widget) {
        render(carousel(props), widget);
    }
};

export default renderCarousel;
