import { html, TemplateResult } from 'lit-html';

import { CarouselProps } from '../../lib/carouselProps';
import { carouselContainerSelector, carouselSelector } from '../../constants';
import { carouselBulletsBlockElement } from '../bullets/bullets';
import { carouselArrowElement } from '../arrows/arrows';
import carouselSlide from '../carouselSlide/carouselSlide';

import { carouselContainer, carouselSlides } from './styles';

const carousel = (props: CarouselProps): TemplateResult => {
    const {
        _: { id },
        slides,
    } = props;

    const carouselSlideElements = slides.map((slide, index): TemplateResult => carouselSlide(props, index));

    return html`
        <div class="${carouselContainerSelector(id)} ${carouselContainer}" id="${carouselContainerSelector(id)}">
            <div class="${carouselSelector(id)} ${carouselSlides}" id="${carouselSelector(id)}">
                ${carouselSlideElements}
            </div>
            ${carouselArrowElement(slides.length)} ${carouselBulletsBlockElement(props)}
        </div>
    `;
};

export default carousel;
