import { CarouselSlideElement } from '../../lib/carouselProps';

interface ContentEditableConfiguration {
    editMode: boolean;
    selector: string;
    onBlur: (content: string) => void;
    onKeyup: (content: string) => void;
    toolbarItems?: ToolbarItem[];
}

export const contentEditable = (uuid: string, attr: keyof CarouselSlideElement, index: number): string =>
    `content-editable-${uuid}-${attr}-${index}`;
export const contentEditableId = (uuid: string, attr: keyof CarouselSlideElement, index: number): string =>
    `.${contentEditable(uuid, attr, index)}`;

export type ToolbarItem = 'bold' | 'italic' | 'strikethrough' | 'link' | 'underline';

export const initContentEditable = ({
    editMode,
    selector,
    onBlur,
    onKeyup,
    toolbarItems,
}: ContentEditableConfiguration): void => {
    if (window.BigCommerce.inlineEditor) {
        window.BigCommerce.inlineEditor.init({
            selector,
            editMode,
            onBlur,
            onKeyup,
            toolbarItems,
        });
    }
};

export const removeInlineEditor = (selector: string): void => {
    if (window.BigCommerce.inlineEditor) {
        window.BigCommerce.inlineEditor.remove(selector);
    }
};

export const getContentEditableParentElement = (event: MouseEvent): HTMLElement | null => {
    let el = event.target as HTMLElement;

    while (el) {
        if (el.dataset && typeof el.dataset.widgetId === 'string') {
            return null;
        }

        if (el.contentEditable === 'true') {
            return el;
        }

        el = el.parentNode as HTMLElement;
    }

    return null;
};
