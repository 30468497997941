import { ImageFit, renderImageVerticalAlignment } from '@bigcommerce/page-builder-sdk';
import { css } from 'emotion';

import { BackgroundType, CarouselSlideElement } from '../../carouselProps';

const renderBackgroundStyles = (props: CarouselSlideElement): string => {
    const {
        background: { color: backgroundColor, imageUrl, type: backgroundType, imageOpacity },
    } = props;
    const backgroundImageStyle = css`
        ::after {
            content: '';
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: -1;
            background: url(${imageUrl.src}) no-repeat;
            background-size: ${props.background.imageFit === ImageFit.Fit ? 'contain' : 'cover'};
            background-position-x: ${props.background.imageAlignment.horizontal};
            background-position-y: ${renderImageVerticalAlignment(props.background.imageAlignment.vertical)};
            opacity: ${imageOpacity / 100};
        }
    `;

    switch (backgroundType) {
        case BackgroundType.IMAGE:
            return backgroundImageStyle;
        case BackgroundType.COLOR:
            return css`
                background-color: ${backgroundColor};
            `;
        default:
            return css`
                background-color: transparent;
            `;
    }
};

export default renderBackgroundStyles;
