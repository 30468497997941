import { svg } from 'lit-html';

import { carouselArrowIcon } from './styles';

export const leftArrowIcon = svg`
    <svg class="${carouselArrowIcon}" viewBox="0 0 17 32" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.9472 0C16.2362 0 16.4839 0.104268 16.6903 0.312805C16.7729 0.375367 16.8349 0.448355 
            16.8761 0.531769C16.9174 0.615184 16.9484 0.703812 16.969 0.797654C16.9897 0.891496 17 0.985337 
            17 1.07918C17 1.17302 16.9897 1.26165 16.969 1.34506C16.9484 1.42848 16.9174 1.51189 16.8761 
            1.59531C16.8349 1.67872 16.7729 1.76214 16.6903 1.84555L2.57013 16.1095L16.5046 30.1857C16.6284 30.3109 
            16.7162 30.4673 16.7678 30.6549C16.8194 30.8426 16.8194 31.0303 16.7678 31.218C16.7162 31.4057 16.6284 31.5621 
            16.5046 31.6872C16.2981 31.8957 16.0452 32 15.7459 32C15.4466 32 15.1937 31.8957 14.9872 31.6872L0.309654 
            16.8602C0.103218 16.6517 0 16.4014 0 16.1095C0 15.8175 0.103218 15.5673 0.309654 15.3587L15.204 0.312805C15.4104 0.104268 15.6582 0 15.9472 0Z"
        />
    </svg>
`;

export const rightArrowIcon = svg`
    <svg class="${carouselArrowIcon}" viewBox="0 0 17 32" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.05282 32C0.763813 32 0.51609 31.8957 0.309654 31.6872C0.227079 31.6246 0.16515 31.5516 0.123861 31.4682C0.0825748 31.3848 0.051609 31.2962 0.0309658 31.2023C0.0103226 31.1085 0 31.0147 0 30.9208C0 30.827 0.0103226 30.7384 0.0309658 30.6549C0.051609 30.5715 0.0825748 30.4881 0.123861 30.4047C0.16515 30.3213 0.227079 30.2379 0.309654 30.1544L14.4299 15.8905L0.495447 1.81427C0.371584 1.68915 0.28385 1.53275 0.232241 1.34506C0.180632 1.15738 0.180632 0.969696 0.232241 0.782013C0.28385 0.59433 0.371584 0.437927 0.495447 0.312805C0.701881 0.104269 0.954765 0 1.2541 0C1.55343 0 1.80631 0.104269 2.01275 0.312805L16.6903 15.1398C16.8968 15.3483 17 15.5986 17 15.8905C17 16.1825 16.8968 16.4327 16.6903 16.6413L1.79599 31.6872C1.58956 31.8957 1.34183 32 1.05282 32Z"
        />
    </svg>
`;
