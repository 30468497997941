import Siema from 'siema';
import { html, TemplateResult } from 'lit-html';

import { arrowAriaLabel, clearAll, getBullets, markActiveBullet, setActive } from '../../services/handleSlideAction';
import { CarouselProps } from '../../lib/carouselProps';
import { LEFT_ARROW, RIGHT_ARROW } from '../arrows/constants';

import { BULLET_CLASS_NAME } from './constants';
import { carouselBullet, carouselBullets } from './styles';

const clickListener = (carousel: Siema, container: HTMLElement, event: Event): void => {
    const bulletElement = event.target as HTMLElement;
    const bullets = getBullets(container);

    clearAll(bullets);

    const selected = parseInt(bulletElement.dataset.bulletIndex, 10);
    carousel.goTo(selected);
    setActive(bullets[selected] as HTMLElement);
};

export const setupCarouselBullets = (
    container: HTMLElement,
    carousel: Siema,
    getEditMode: () => boolean,
    slideCount: number
): void => {
    markActiveBullet(container, carousel);
    const leftArrow = container.querySelector(`.${LEFT_ARROW}`) as HTMLElement;
    const rightArrow = container.querySelector(`.${RIGHT_ARROW}`) as HTMLElement;
    getBullets(container).forEach((bullet: Element): void => {
        bullet.addEventListener('click', (event: Event): void => {
            const editMode = getEditMode();
            if (editMode) {
                return;
            }
            clickListener(carousel, container, event);
            if (leftArrow && rightArrow) {
                arrowAriaLabel(leftArrow, rightArrow, slideCount, carousel.currentSlide + 1);
            }
        });
    });
};

const carouselBulletElement = (props: CarouselProps, index: number): TemplateResult => {
    return html`
        <button
            class="${carouselBullet} ${BULLET_CLASS_NAME}"
            data-bullet-index="${index}"
            data-test-id="carousel-bullet-${index}"
            aria-label="Go to slide ${index + 1}"
        ></button>
    `;
};

export const carouselBulletsBlockElement = (props: CarouselProps): TemplateResult => {
    const bullets = props.slides.map((slide, index): TemplateResult => carouselBulletElement(props, index));

    return html`
        <div class="${carouselBullets}" data-test-id="carousel-bullets">
            ${bullets}
        </div>
    `;
};
