import isEditModeDisabled from '../lib/isEditModeDisabled';
import { CarouselProps } from '../lib/carouselProps';

function handleLinkRedirect(event: MouseEvent, props: CarouselProps, index: number): void {
    const { slideLink } = props.slides[index];
    const hasLink = slideLink && slideLink.trim();

    // checking editMode is off or undefined
    if (isEditModeDisabled(props) && hasLink) {
        const slideUrlParts = slideLink.split('/');
        const [protocol] = slideUrlParts;

        // check if link is not properly formatted
        if (protocol !== '' && !protocol.includes('http')) {
            slideUrlParts.unshift('/');
        }

        const newSlideUrl = slideUrlParts.join('/');
        window.open(newSlideUrl, '_top');
    }
}

export default handleLinkRedirect;
