import { css } from 'emotion';
import { renderImageVerticalAlignment, renderFlexBoxAlignment } from '@bigcommerce/page-builder-sdk';

import { CarouselSlideElement } from '../../lib/carouselProps';
import renderBackgroundStyles from '../../lib/widgetSchema/backgroundStyles/renderBackgroundStyles';

export const carouselSlideElementBlockStyle = (props: CarouselSlideElement): string => {
    return css`
        display: flex;
        ${renderBackgroundStyles(props)};
        min-height: 300px;
        align-items: ${renderImageVerticalAlignment(props.contentAlignment.vertical)};
        justify-content: ${renderFlexBoxAlignment(props.contentAlignment.horizontal)};
        text-align: ${props.contentAlignment.horizontal};
        padding: 32px;
        height: 100%;

        @media screen and (min-width: 801px) {
            padding: 74px 84px;
            min-height: 500px;
        }

        @media screen and (min-width: 1261px) {
            padding: 74px 84px;
            min-height: 500px;
        }
    `;
};

export const carouselSlideStyle = css`
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    position: relative;
`;
