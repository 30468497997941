import { css } from 'emotion';

export const carouselArrows = css`
    -webkit-touch-callout: none;
    user-select: none;

    @media screen and (max-width: 800px) {
        display: none;
    }
`;

export const carouselArrow = css`
    position: absolute;
    display: block;
    top: 50%;
    z-index: 100;
    border: none;
    color: #5e637a;
    background: #ffffff;
    opacity: 0.9;
    height: 168px;
    width: 52px;
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
    transform: translateY(-50%);
    line-height: 1;

    :focus {
        outline: 0;
    }
`;

export const carouselArrowLeft = css`
    left: 0em;
    border-radius: 0px 4px 4px 0px;
`;

export const carouselArrowRight = css`
    right: 0em;
    border-radius: 4px 0px 0px 4px;
`;

export const carouselArrowIcon = css`
    height: 32px;
    width: 17px;
    fill: none;

    > path {
        fill: #5e637a;
    }
`;
